:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}

._inputTextContainer_1s14s_3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

._fieldContainer_1s14s_10 {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  border: 1px solid;
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 12px;
  gap: 20px;
}

._fieldContainer_1s14s_10._required_1s14s_21 {
    border-left: 6px solid;
  }

._fieldContainer_1s14s_10._dark_1s14s_25 {
    background-color: #141414;
    border-color: #434343;
  }

._fieldContainer_1s14s_10._dark_1s14s_25._error_1s14s_29 {
      border-color: #F5222D;
    }

._fieldContainer_1s14s_10._dark_1s14s_25._warning_1s14s_32 {
      border-color: #FABC16;
    }

._fieldContainer_1s14s_10._dark_1s14s_25._success_1s14s_35 {
      border-color: #52C41A;
    }

._fieldContainer_1s14s_10._dark_1s14s_25 ._iconFill_1s14s_39._error_1s14s_29 {
        fill: #FFA39E;
      }

._fieldContainer_1s14s_10._dark_1s14s_25 ._iconFill_1s14s_39._warning_1s14s_32 {
        fill: #FFD591;
      }

._fieldContainer_1s14s_10._dark_1s14s_25 ._iconFill_1s14s_39._success_1s14s_35 {
        fill: #B7EB8F;
      }

._fieldContainer_1s14s_10._light_1s14s_52 {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
  }

._fieldContainer_1s14s_10._light_1s14s_52._error_1s14s_29 {
      border-color: #FF3D4F;
    }

._fieldContainer_1s14s_10._light_1s14s_52._warning_1s14s_32 {
      border-color: #FFA940;
    }

._fieldContainer_1s14s_10._light_1s14s_52._success_1s14s_35 {
      border-color: #73D13D;
    }

._fieldContainer_1s14s_10._light_1s14s_52 ._iconFill_1s14s_39._error_1s14s_29 {
        fill: #A8071A;
      }

._fieldContainer_1s14s_10._light_1s14s_52 ._iconFill_1s14s_39._warning_1s14s_32 {
        fill: #AD4E00;
      }

._fieldContainer_1s14s_10._light_1s14s_52 ._iconFill_1s14s_39._success_1s14s_35 {
        fill: #237804;
      }

._fieldContainer_1s14s_10 ._iconContainer_1s14s_79 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

._fieldContainer_1s14s_10 ._iconContainer_1s14s_79 ._iconFill_1s14s_39 {
      fill: #1890ff;
    }

._fieldContainer_1s14s_10 ._input_1s14s_3 {
    font-family: 'latoregular';
    border: none;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

._fieldContainer_1s14s_10 ._input_1s14s_3:focus {
      outline: none;
    }

._fieldContainer_1s14s_10 ._input_1s14s_3._dark_1s14s_25 {
      background-color: #141414;
      color: #F5F5F5;
    }

._fieldContainer_1s14s_10 ._input_1s14s_3._dark_1s14s_25._error_1s14s_29 {
        color: #FFA39E;
      }

._fieldContainer_1s14s_10 ._input_1s14s_3._dark_1s14s_25._warning_1s14s_32 {
        color: #FFD591;
      }

._fieldContainer_1s14s_10 ._input_1s14s_3._dark_1s14s_25._success_1s14s_35 {
        color: #B7EB8F;
      }

._fieldContainer_1s14s_10 ._input_1s14s_3._light_1s14s_52 {
      background-color: #F5F5F5;
      color: #1F1F1F;
    }

._fieldContainer_1s14s_10 ._input_1s14s_3._light_1s14s_52._error_1s14s_29 {
        color: #A8071A;
      }

._fieldContainer_1s14s_10 ._input_1s14s_3._light_1s14s_52._warning_1s14s_32 {
        color: #AD4E00;
      }

._fieldContainer_1s14s_10 ._input_1s14s_3._light_1s14s_52._success_1s14s_35 {
        color: #237804;
      }
