/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._recovery1_1tf1x_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
}
._recovery1_1tf1x_4 ._imageContainer_1tf1x_10 {
    width: 80px;
    height: 52px;
    margin-bottom: 38px;
  }
._recovery1_1tf1x_4 ._textContainer_1tf1x_16 {
    font-family: 'latoregular';
    font-size: 24px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    text-align: center;
  }
._recovery1_1tf1x_4 ._textContainer_1tf1x_16._dark_1tf1x_22 {
      color: #F5F5F5;
    }
._recovery1_1tf1x_4 ._textContainer_1tf1x_16._light_1tf1x_25 {
      color: #1F1F1F;
    }
._recovery1_1tf1x_4 ._formContainer_1tf1x_30 {
    display: flex;
    flex-direction: column;
    width: 360px;
    margin-top: 25px;
    gap: 16px;
    align-items: center;
  }
._recovery1_1tf1x_4 ._formContainer_1tf1x_30 ._buttonContainer_1tf1x_38 {
      width: 164px;
    }
