/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._recovery4_1tu9w_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
}
._recovery4_1tu9w_4 ._imageContainer_1tu9w_10 {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }
._recovery4_1tu9w_4 ._textContainer_1tu9w_16 {
    font-family: 'latoregular';
    font-size: 24px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
._recovery4_1tu9w_4 ._textContainer_1tu9w_16._dark_1tu9w_21 {
      color: #F5F5F5;
    }
._recovery4_1tu9w_4 ._textContainer_1tu9w_16._light_1tu9w_24 {
      color: #1F1F1F;
    }
._recovery4_1tu9w_4 ._buttonContainer_1tu9w_29 {
    margin-top: 24px;
    width: 153px;
  }
