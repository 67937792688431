/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._viewContainer_1nyfq_4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
._viewContainer_1nyfq_4 ._preferencesContainer_1nyfq_10 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: right;
    align-items: center;
  }
._viewContainer_1nyfq_4 ._logoContainer_1nyfq_21 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 87px;
    margin-top: 52px;
    justify-content: center;
  }
._viewContainer_1nyfq_4 ._formContainer_1nyfq_30 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 82px;
  }
._viewContainer_1nyfq_4 ._formContainer_1nyfq_30 ._tabContainer_1nyfq_37 {
      width: 360px;
    }
._viewContainer_1nyfq_4 ._formContainer_1nyfq_30 ._separator_1nyfq_41 {
      width: 360px;
      height: 1px;
    }
._viewContainer_1nyfq_4 ._formContainer_1nyfq_30 ._separator_1nyfq_41._dark_1nyfq_45 {
        background-color: #434343;
      }
._viewContainer_1nyfq_4 ._formContainer_1nyfq_30 ._separator_1nyfq_41._light_1nyfq_48 {
        background-color: #D9D9D9;
      }
._viewContainer_1nyfq_4 ._footerContainer_1nyfq_54 {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 22px;
    font-family: 'latoregular';
  }
._viewContainer_1nyfq_4 ._footerContainer_1nyfq_54._dark_1nyfq_45 {
      color: #8C8C8C;
    }
._viewContainer_1nyfq_4 ._footerContainer_1nyfq_54._light_1nyfq_48 {
      color: #8C8C8C;
    }
