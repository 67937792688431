/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._viewContainer_s6j9y_4 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 48px;
        padding-left: 16px;
        padding-right: 16px;
        justify-content: right;
        align-items: center;
    }
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 ._buttonContainer_s6j9y_20 {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;    
        }
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 ._buttonContainer_s6j9y_20 ._svgIcon_s6j9y_28 {
                max-width: 20px;
                max-height: 20px;
                fill-opacity: 0.7;
            }
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 ._buttonContainer_s6j9y_20 ._svgIcon_s6j9y_28._dark_s6j9y_33 {
                    fill: #F5F5F5;
                }
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 ._buttonContainer_s6j9y_20 ._svgIcon_s6j9y_28._light_s6j9y_37 {
                    fill: #1F1F1F;
                }
._viewContainer_s6j9y_4 ._preferencesContainer_s6j9y_10 ._buttonContainer_s6j9y_20 ._svgIcon_s6j9y_28:hover {
                    fill-opacity: 1;
                }
._viewContainer_s6j9y_4 ._logoContainer_s6j9y_48 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 87px;
        margin-top: 52px;
        justify-content: center;
    }
._viewContainer_s6j9y_4 ._footerContainer_s6j9y_57 {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        margin-bottom: 22px;
        font-family: 'latoregular';
    }
._viewContainer_s6j9y_4 ._footerContainer_s6j9y_57._dark_s6j9y_33 {
            color: #8C8C8C;
        }
._viewContainer_s6j9y_4 ._footerContainer_s6j9y_57._light_s6j9y_37 {
            color: #8C8C8C;
        }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 119px;
    }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 488px;

        }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._imageContainer_s6j9y_87 {
                width: 80px;
                height: 52px;
                margin-bottom: 38px;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93 {
                font-family: 'latoregular';
                font-size: 24px;
                -webkit-user-select: none;
                   -moz-user-select: none;
                        user-select: none;
                text-align: center;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93._dark_s6j9y_33 {
                    color: #F5F5F5;
                 }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93._light_s6j9y_37 {
                    color: #1F1F1F;
                 }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93 ._smallTextContainer_s6j9y_106 {
                    font-size: 14px;
                    margin-top: 5px;
                 }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93 ._smallTextContainer_s6j9y_106._dark_s6j9y_33 {
                        color: #8C8C8C;
                    }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._textContainer_s6j9y_93 ._smallTextContainer_s6j9y_106._light_s6j9y_37 {
                        color: #8C8C8C;
                    }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._formContainer_s6j9y_119 {
                display:flex;
                flex-direction: row;
                width: 360px;
                margin-top: 25px;
                gap: 16px;
                align-items: center;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._formContainer_s6j9y_119 ._buttonContainer_s6j9y_20 {
                    width: 164px;
                }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery1_s6j9y_81 ._resendContainer_s6j9y_132 {
                margin-top: 20px;
                font-family: 'latoregular';
                font-size: 14px;
                color: #1890ff;
                -webkit-user-select: none;
                   -moz-user-select: none;
                        user-select: none;
                cursor: pointer;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 488px;
        }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 ._imageContainer_s6j9y_87 {
                width: 80px;
                height: 80px;
                margin-bottom: 24px;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 ._textContainer_s6j9y_93 {
                font-family: 'latoregular';
                font-size: 24px;
                -webkit-user-select: none;
                   -moz-user-select: none;
                        user-select: none;
                text-align: center;
            }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 ._textContainer_s6j9y_93._dark_s6j9y_33 {
                    color: #F5F5F5;
                }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 ._textContainer_s6j9y_93._light_s6j9y_37 {
                    color: #1F1F1F;
                }
._viewContainer_s6j9y_4 ._stepsContainer_s6j9y_75 ._recovery2_s6j9y_143 ._buttonContainer_s6j9y_20 {
                margin-top: 24px;
                width: 153px;
            }