:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}

._feedbackContainer_1y0l4_3 {
    width: 100%;
    height: 30px;
    font-family: 'latoregular';
    font-size: 12px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: default;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
}

._feedbackContainer_1y0l4_3._dark_1y0l4_15._error_1y0l4_16 {
            color: #FFA39E;
            background-color: #820014;
        }

._feedbackContainer_1y0l4_3._dark_1y0l4_15._warning_1y0l4_21 {
            color: #FFD591;
            background-color: #873800;
        }

._feedbackContainer_1y0l4_3._dark_1y0l4_15._success_1y0l4_26 {
            color: #B7EB8F;
            background-color: #135200;
        }

._feedbackContainer_1y0l4_3._light_1y0l4_32._error_1y0l4_16 {
            color: #A8071A;
            background-color: #FFCCC7;
        }

._feedbackContainer_1y0l4_3._light_1y0l4_32._warning_1y0l4_21 {
            color: #AD4E00;
            background-color: #FFE7BA;
        }

._feedbackContainer_1y0l4_3._light_1y0l4_32._success_1y0l4_26 {
            color: #237804;
            background-color: #D9F7BE;
        }