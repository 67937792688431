/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._viewContainer_l621b_4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  color: #fff;
  font-size: 25px;
}
