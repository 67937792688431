/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._tabsContainer_1uk4g_4 {
    width: 100%;
}
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 {
        display: flex;
        height: 50px;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 15px;
        margin-bottom: 5px;
        gap: 32px;

    }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4 {
            font-size: 14px;
            font-family: 'latoregular';
            cursor: pointer;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            opacity: .7;
        }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4:hover {
                opacity: 1;
            }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4._dark_1uk4g_27 {
                color: #F5F5F5;
            }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4 ._light_1uk4g_31 {
                color: #1F1F1F;
            }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4 ._decorator_1uk4g_35 {
                display: hidden;
            }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4._active_1uk4g_41 {
                opacity: 1;
            }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4._active_1uk4g_41._dark_1uk4g_27 {
                    color: #1890ff;
                }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4._active_1uk4g_41._light_1uk4g_31 {
                    color: #1F1F1F;
                }
._tabsContainer_1uk4g_4 ._tabsListContainer_1uk4g_7 ._tab_1uk4g_4._active_1uk4g_41 ._decorator_1uk4g_35 {
                    margin-top: 5px;
                    height: 2px;
                    width: 100%;
                    background-color: #1890ff;
                }