/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._viewContainer_10qa4_4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
._viewContainer_10qa4_4 ._preferencesContainer_10qa4_10 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: right;
    align-items: center;
  }
._viewContainer_10qa4_4 ._logoContainer_10qa4_21 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 87px;
    margin-top: 52px;
    justify-content: center;
  }
._viewContainer_10qa4_4 ._footerContainer_10qa4_30 {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 22px;
    font-family: 'latoregular';
  }
._viewContainer_10qa4_4 ._footerContainer_10qa4_30._dark_10qa4_39 {
      color: #8C8C8C;
    }
._viewContainer_10qa4_4 ._footerContainer_10qa4_30._light_10qa4_43 {
      color: #8C8C8C;
    }
