/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._buttonContainer_ryi4p_4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 2px;
    font-family: 'latoregular';
    font-size: 14px;
    background-color: #1890ff;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: pointer;

}
._buttonContainer_ryi4p_4._dark_ryi4p_17 {
        color: #F5F5F5;
    }
._buttonContainer_ryi4p_4._light_ryi4p_21 {
        color: #1F1F1F;
    }
._buttonContainer_ryi4p_4:hover {
        background-color: #40a9ff;
    }
._buttonContainer_ryi4p_4:active {
        background-color: #40a9ff;
    }