/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._sysprefWidgetContainer_7f3ci_4 {
    display: flex;
    flex-direction: row;
    height: 48px;
    gap: 16px;
}
._sysprefWidgetContainer_7f3ci_4 ._themeContainer_7f3ci_10 {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        cursor: pointer;    

    }
._sysprefWidgetContainer_7f3ci_4 ._themeContainer_7f3ci_10 ._svgIcon_7f3ci_17 {
            max-width: 20px;
            max-height: 20px;
            fill-opacity: 0.7;
        }
._sysprefWidgetContainer_7f3ci_4 ._themeContainer_7f3ci_10 ._svgIcon_7f3ci_17._dark_7f3ci_22 { fill: #F5F5F5; }
._sysprefWidgetContainer_7f3ci_4 ._themeContainer_7f3ci_10 ._svgIcon_7f3ci_17._light_7f3ci_23 { fill: #1F1F1F; }
._sysprefWidgetContainer_7f3ci_4 ._themeContainer_7f3ci_10 ._svgIcon_7f3ci_17:hover { fill-opacity: 1; }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: 'latoregular';
        font-size: 14px;
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
        opacity: 0.7;    
    }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29._dark_7f3ci_22 { color: #8C8C8C; }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29._light_7f3ci_23 { color: #8C8C8C; }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29:hover { opacity: 1; }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29 ._svgIcon_7f3ci_17 {
            max-width: 20px;
            max-height: 20px;
        }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29 ._svgIcon_7f3ci_17._dark_7f3ci_22 { fill: #F5F5F5; }
._sysprefWidgetContainer_7f3ci_4 ._languageContainer_7f3ci_29 ._svgIcon_7f3ci_17._light_7f3ci_23 { fill: #1F1F1F; }