/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._recovery3_ey1h4_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
}
._recovery3_ey1h4_4 ._imageContainer_ey1h4_10 {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }
._recovery3_ey1h4_4 ._textContainer_ey1h4_16 {
    font-family: 'latoregular';
    font-size: 24px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
._recovery3_ey1h4_4 ._textContainer_ey1h4_16._dark_ey1h4_21 {
      color: #F5F5F5;
    }
._recovery3_ey1h4_4 ._textContainer_ey1h4_16._light_ey1h4_24 {
      color: #1F1F1F;
    }
._recovery3_ey1h4_4 ._formContainer_ey1h4_29 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 77px;
    width: 360px;
    gap: 16px;
  }
._recovery3_ey1h4_4 ._formContainer_ey1h4_29 ._buttonContainer_ey1h4_37 {
      width: 160px;
    }
._recovery3_ey1h4_4 ._resendContainer_ey1h4_42 {
    margin-top: 20px;
    font-family: 'latoregular';
    font-size: 14px;
    color: #1890ff;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: pointer;
  }
