/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._appContainer_96vnl_5 {
  position: relative;
  display: block;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-image: url('../public/assets/images/background-network.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
._appContainer_96vnl_5._dark_96vnl_18 {
    background-color: #141414;
  }
._appContainer_96vnl_5._light_96vnl_22 {
    background-color: #F5F5F5;
  }
._appContainer_96vnl_5 ._backgroundGradient_96vnl_26 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

  }
._appContainer_96vnl_5 ._backgroundGradient_96vnl_26._dark_96vnl_18 {
       background: linear-gradient(
        90deg, 
        rgba(20, 20, 20, 0.1) 0%, 
        rgba(20, 20, 20, 0.8) 50%,
        rgba(20, 20, 20, 0.1) 100%
      );
    }
._appContainer_96vnl_5 ._backgroundGradient_96vnl_26._light_96vnl_22 {
      background: linear-gradient(
        90deg,
        rgba(245, 245, 245, 0.1) 0%, 
        rgba(245, 245, 245, 0.8) 50%,
        rgba(245, 245, 245, 0.1) 100%       
      );
    }
._appContainer_96vnl_5 ._contentContainer_96vnl_54 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

