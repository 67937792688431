:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}

._loaderContainer_z08rc_3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: transparent;
}

._loaderContainer_z08rc_3._dark_z08rc_16 {
    animation: _fadeInDark_z08rc_1 0.5s forwards;
  }

._loaderContainer_z08rc_3._light_z08rc_19 {
    animation: _fadeInLight_z08rc_1 0.5s forwards;
  }

._loaderContainer_z08rc_3 ._loaderBox_z08rc_23 {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    position: relative;
  }

._loaderContainer_z08rc_3 ._loaderBox_z08rc_23._dark_z08rc_16 {
      background-color: #141414;
    }

._loaderContainer_z08rc_3 ._loaderBox_z08rc_23._light_z08rc_19 {
      background-color: #F5F5F5;
    }

._loaderContainer_z08rc_3 ._loaderBox_z08rc_23 ._loader_z08rc_3 {
      width: 100px;
      aspect-ratio: 1;
      padding: 10px;
      box-sizing: border-box;
      display: grid;
    }

._loaderContainer_z08rc_3 ._loaderBox_z08rc_23 ._loader_z08rc_3:before {
      content: '';
      margin: auto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #2baee7;
      background: currentColor;
      box-shadow: -30px 0, 30px 0, 0 30px, 0 -30px;
      animation: _l6_z08rc_1 0.7s infinite alternate;
    }

@keyframes _l6_z08rc_1 {
  90%,
  100% {
    box-shadow: -10px 0, 10px 0, 0 10px, 0 -10px;
    transform: rotate(180deg);
  }
}

@keyframes _fadeInDark_z08rc_1 {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(245, 245, 245, 0.2);
  }
}

@keyframes _fadeInLight_z08rc_1 {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(31, 31, 31, 0.2);
  }
}
