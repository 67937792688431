/* THEMES */
:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}
._loginContainer_qdb1e_4 {
  width: 360px;
}
._loginContainer_qdb1e_4 ._inputsContainer_qdb1e_7 {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
._loginContainer_qdb1e_4 ._errorContainer_qdb1e_14 {
    margin-top: 16px;
  }
._loginContainer_qdb1e_4 ._loginOptionsContainer_qdb1e_18 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 23px;
  }
._loginContainer_qdb1e_4 ._loginOptionsContainer_qdb1e_18 ._forgotPasswordContainer_qdb1e_24 {
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
      cursor: pointer;
      color: #1890ff;
      font-family: 'latoregular';
      font-size: 14px;
      display: flex;
      justify-content: end;
      flex-wrap: nowrap;
      width: 100%;
    }
._loginContainer_qdb1e_4 ._actionsContainer_qdb1e_37 {
    display: flex;
    margin-top: 22px;
    width: 67px;
  }
