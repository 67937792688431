:root {
    /* Brand Palette */

    /* Gray Palette */

    /* Daybreak Blue Palette */

    /* Dust Red */

    /* Polar Green */

    /* Sunset Orange */

}

._checkboxContainer_ujvda_3 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
}

._checkboxContainer_ujvda_3 ._input_ujvda_9 {
        width: 16px;
        height: 16px;
    }

._checkboxContainer_ujvda_3 ._input_ujvda_9._dark_ujvda_13 {

        }

._checkboxContainer_ujvda_3 ._input_ujvda_9._light_ujvda_17 {

        }

._checkboxContainer_ujvda_3 ._labelContainer_ujvda_22 {
        font-family: 'latoregular';
        font-size: 14px;
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
    }

._checkboxContainer_ujvda_3 ._labelContainer_ujvda_22._dark_ujvda_13 {
            color: #F5F5F5;
        }

._checkboxContainer_ujvda_3 ._labelContainer_ujvda_22._light_ujvda_17 {
            color: #1F1F1F;
        }